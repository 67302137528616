.controlsContainer {
  position: absolute;
  right: 3em;
  top: 1em;
}

.controlGroup {
  margin-top: 1em;
}

.menuItem {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.blackIcon {
  fill: black !important;
}
