.iconTooltip {
  height: 100%;
}

.selectedMarker {
  background-color: #eceff1;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 3px 0 0 0;
}
