.widget {
  font-size: 1.5em;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 0.5rem;
  padding: 5% 7%;
  height: 100%;
  width: 100%;
  color: black;
}

.selection-panel {
  margin: 1.8rem 0 1.8rem -0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.selection-column {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  align-items: center;
}

.temp-cell {
  display: flex;
  flex-flow: column wrap;
}

.cloudy {
  background-image: linear-gradient(0deg, #bbdefb, #2196f3);
}

.clear {
  background-image: linear-gradient(0deg, #b3e5fc, #29b6f6);
}

.snow {
  background-image: linear-gradient(0deg, #b2ebf2, #26c6da);
}

.rain {
  background-image: linear-gradient(0deg, #c5cae9, #5c6bc0);
}

.thunderstorm {
  background-image: linear-gradient(0deg, #e0e0e0, #757575);
}

.openweathermap-credits {
  color: black;
  font-size: 11px;
  position: absolute;
  bottom: 3px;
  right: 6px;
}
