.mapContainer {
  /* This allows editor controls positioning */
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.messageContainer { 
  z-index: 10;
  background: #81818166;
  left: 60;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  display: flex;
}



.message {
  margin: auto;
  text-align: center;
  vertical-align: middle;
  font-size: 1.65em;
}
