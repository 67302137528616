.error404 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: var(--align-items);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    z-index: 0;
    height: calc(100vh - 50px);
    width: calc(100vw - var(--sidebar-width));
}

.pageNotFound {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    color: #5E5D52;
    width: 100%;
}