.files-dropzone {
  border: 3px dashed #cccccc;
  margin: 0 0 20px 0;
  padding: 20px;
}

.files-dropzone-active {
  background-color: #cccccc;
  color: #ffffff;
}
