.logo > a {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-normal {
  max-width: 100%;
  max-height: 90%;
}

.logo-small {
  display: none;
  max-width: 100%;
  max-height: 100%;
}

.app.nav-collapsed-min .top-header .logo .logo-normal {
  display: none;
}

.app.nav-collapsed-min .top-header .logo .logo-small {
  display: block;
}
