.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
  }
  
  .action:hover {
    text-decoration: none;
  }
  
  .action .narrowButton {
    padding: 2px;
    min-width: initial;
  }