.customScrollbar {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;

  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(0,0,0,0) #5d5d5d; /* Firefox */
  scrollbar-track-color: #5d5d5d; /* IE */
  scrollbar-base-color: #5d5d5d; /* IE */
  scrollbar-face-color: rgba(0,0,0,0); /* IE */
}

.customScrollbar:hover {
  scrollbar-color: #303030 #5d5d5d; /* Firefox */
  scrollbar-track-color: #5d5d5d; /* IE */
  scrollbar-base-color: #5d5d5d; /* IE */
  scrollbar-face-color: #303030; /* IE */
}

.customScrollbar::-webkit-scrollbar {
  width: 5px;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: #5d5d5d;
}


.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0,0,0,0);
}

.customScrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #303030;
}
