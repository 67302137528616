.roles-action-buttons {
  height: 32px !important;
  line-height: inherit !important;
  min-width: 0 !important;
  padding: 6px 5px 6px 5px !important;
  top: -12px;
  width: 32px;
}

/* TREE */
.listHeader {
  border-bottom: 1px solid rgb(224, 224, 224);
  padding-bottom: 10px;
}
.miChip {
  display: inline-block !important;
  margin: -1px 0 0 10px !important;
}
.miChip span {
  line-height: 21px !important;
}

.rolesList > div > span > div > span > div > div {
  padding: 16px 26px 16px 36px !important;
}
.rolesList svg {
  left: -8px;
  right: inherit !important;
}

.privilegeList > div > span > div > span > div > div {
  padding: 16px 36px !important;
}

.privilegeList > div > span > div > span > div > div > div {
  margin-left: -24px;
}
.privilegeList label {
  margin-left: -15px;
}

.privilegeList svg {
  left: -8px;
  right: inherit !important;
}
