.customScroll {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 45vh;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #CCCCCC transparent; /* Firefox */
  scrollbar-track-color: transparent; /* IE */
  scrollbar-base-color: transparent; /* IE */
  scrollbar-face-color: #CCCCCC; /* IE */
}

.customScroll::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.customScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #CCCCCC;
}