.device {
    max-width: 100%;
    height: 362px;
    display:flex;
}

.deviceImg {
    max-width: 100%;   
    max-height: 100%;
    margin: auto;
}