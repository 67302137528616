.users .users-filter {
  display: inline-block;
  padding: 10px 0px 10px 15px;
}
.users .users-filter i {
  color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  position: relative;
  left: -20px;
}

.users .orderable {
  display: block;
  cursor: pointer;
}
.users .orderable .table_order {
  float: right;
}

.users .orderable.order_asc .table_order:before {
  content: '\f15d';
}

.users .orderable.order_desc .table_order:before {
  content: '\f15e';
}

.users .users-action-buttons {
  height: 25px !important;
  line-height: inherit !important;
  min-width: 0 !important;
  padding: 0 5px !important;
}
