.marker-100 {
  z-index: 100;
}

.marker-1000 {
  z-index: 1000;
}

.marker-10000 {
  z-index: 10000;
}

.marker-12000 {
  z-index: 12000;
}
