.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.action {
  margin-right: 6px;
}

.action:hover {
  text-decoration: none;
}

.action .narrowButton {
  padding: 2px;
  min-width: initial;
}