.controlsContainer {
  position: absolute;
  left: 3em;
  top: 1em;
  width: 200px;
}

.menuItem { 
  width: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: solid 1px gray;
  background-color: white;
  font-weight: bold;
}

.menuItem:hover { 
  background-color: rgba(255, 255, 255, 0.65);
}
