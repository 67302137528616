@charset 'utf-8';
/*!
* https://sass-guidelin.es/#the-7-1-pattern
* https://github.com/HugoGiraudel/sass-boilerplate
*
* Orden de importación de los archivos:
*
* 1 - abstracts/
* 2 - vendors/
* 3 - base/
* 4 - layout/
* 5 - components/
* 6 - pages/
* 7 - themes/
*/

// html,
// body {
//   height: 100%;
//   margin: 0;
//   padding: 0;
// }

#root {
  height: 100%;
}

.app.nav-collapsed-min .nav-container + .content-container .dashboard-wrapper {
  @media (min-width: 768px) {
    margin-left: -30px;
  }
}

.PD-content-grid {
  background: #ffffff;
  display: block;
  overflow: hidden;
  position: relative;

  .nav-collapsed-min & {
    margin-left: 30px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  &.PD-edit {
    background: #ffffff
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADhJREFUeNpi/P//PwMh8Pv37/+srKyMxKhjYqAyGDVw1EAyAOOvX7/+U9XA0ZwyauBoTiECAAQYAGPNHdnZaAVPAAAAAElFTkSuQmCC')
      0 0 repeat;
    display: block;
    margin: 25px 0 0 24px;
    overflow: visible;
    position: relative;
  }
}

.app.nav-collapsed-min .nav-container + .content-container .dashboard-wrapper .PD-content-grid.PD-edit {
  margin: 25px 0 0 54px;
}

.PD-widget {
  align-items: center;
  border: solid 1px transparent;
  cursor: default !important;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0;

  &.PD-edit {
    border: 1px dotted #cccccc;
    border-radius: 3px;
    cursor: default !important;
    overflow: visible;

    &:hover {
      outline: 2px dotted #555;
    }
  }

  &.PD-widget_manipulating {
    background-color: #cccccc;
    box-shadow: 0 0 10px #cccccc;
  }
}

.notConfigured {
  display: none;
}

.PD-edit .notConfigured {
  display: block;
}

.PD-widget_resizeHandler_topLeft {
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFzs7O////TowvlgAAAAJ0Uk5T/wDltzBKAAAAHUlEQVR42mJggANGOGDAIciIAQajICPjMBMECDAAF1MBbcLEtdQAAAAASUVORK5CYII=')
    0 0 no-repeat;
  cursor: nw-resize !important;
  left: 2px !important;
  top: 2px !important;

  opacity: 0;
  transition: opacity 0.4s;
}

.PD-widget:hover .PD-widget_resizeHandler_topLeft {
  opacity: 1;
}

.PD-widget_resizeHandler_topRight {
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFzs7O////TowvlgAAAAJ0Uk5T/wDltzBKAAAAG0lEQVR42mJghAMGBCAgiCQ5JASBwkNZECDAABk7AW3KoXMRAAAAAElFTkSuQmCC')
    0 0 no-repeat;
  cursor: ne-resize !important;
  right: 2px !important;
  top: 2px !important;

  opacity: 0;
  transition: opacity 0.4s;
}

.PD-widget:hover .PD-widget_resizeHandler_topRight {
  opacity: 1;
}

.PD-widget_resizeHandler_bottomLeft {
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFzs7O////TowvlgAAAAJ0Uk5T/wDltzBKAAAAGklEQVR42mJgxAIYhrQgA8NQEYQBAoIAAQYAP1sBbb0JOiAAAAAASUVORK5CYII=')
    0 0 no-repeat;
  bottom: 2px !important;
  cursor: sw-resize !important;
  left: 2px !important;

  opacity: 0;
  transition: opacity 0.4s;
}

.PD-widget:hover .PD-widget_resizeHandler_bottomLeft {
  opacity: 1;
}

.PD-widget_resizeHandler_bottomRight {
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFzs7O////TowvlgAAAAJ0Uk5T/wDltzBKAAAAG0lEQVR42mJgxAIYhpkgA8PgFmRAAGyCAAEGAEFDAW1p/FajAAAAAElFTkSuQmCC')
    0 0 no-repeat;
  bottom: 2px !important;
  cursor: se-resize !important;
  right: 2px !important;

  opacity: 0;
  transition: opacity 0.4s;
}

.PD-widget:hover .PD-widget_resizeHandler_bottomRight {
  opacity: 1;
}

.PD-widget_dragHandler {
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkI3NkU3MTdGOUJFOTExRTk5QTgyOUEwQ0MxOEE4NjMyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkI3NkU3MTgwOUJFOTExRTk5QTgyOUEwQ0MxOEE4NjMyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qjc2RTcxN0Q5QkU5MTFFOTlBODI5QTBDQzE4QTg2MzIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qjc2RTcxN0U5QkU5MTFFOTlBODI5QTBDQzE4QTg2MzIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4G93FcAAAAm0lEQVR42txUQQ7AIAgbxhfp/5+gX2JziQkjRTjoZZxmhrUtDcTM185K1+baDpjlgYhgU2vt9aXWChukbS7DCaa/raLVUHrv8GcphaQiiUGRKU/gCaQtciVbzCI9KdI4mGl2lqcfyRZY5MFjOdRD4YcBZGZZM7K5GsrwCl5GUlHQM2KNQD1vjwU7OSzCzKLLgSLLISz5nwv2FmAAvTVoEOtuHp8AAAAASUVORK5CYII=')
    50% 50% no-repeat;
  background-color: #5d5d5d;
  border-radius: 20px;
  cursor: move;
  height: 20px;
  left: 0;
  position: absolute;
  top: calc(50% - 10px);
  width: 20px;

  opacity: 0;
  transition: opacity 0.4s;
}

.PD-widget:hover .PD-widget_dragHandler {
  opacity: 1;
}

.PD-widget_config {
  background: transparent
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRF////////VXz1bAAAAAJ0Uk5T/wDltzBKAAAAO0lEQVR42mJgxAIYyBBkYEAXZIACQoIMSABFEIWGcuCmwAQZkAUZ8Ali1Y7TIkwnEe8jHAFCbiADBBgA9HQBKROeiqoAAAAASUVORK5CYII=')
    0 0 no-repeat;
  background-color: #000000;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 2px;
  top: 0px;
  width: 20px;

  opacity: 0;
  transition: opacity 0.4s;

  &:hover {
    background: transparent
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkZEQ0RDMjcxOUJFODExRTlCNzgxQkY1MzYyODUwRDVFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkZEQ0RDMjcyOUJFODExRTlCNzgxQkY1MzYyODUwRDVFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RkRDREMyNkY5QkU4MTFFOUI3ODFCRjUzNjI4NTBENUUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RkRDREMyNzA5QkU4MTFFOUI3ODFCRjUzNjI4NTBENUUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/9pN7AAAAaklEQVR42uRUMQ4AIQizhv9/uTdd4iBKCdFBNg2UUqog2Sqjt+IoB7TxAMDL+3WZJoyy2aIZN3c4P/KCWVgGCxRCadhFMFc7DzDjch41NhIYUBhSdYGpGmV8iCDQvZcyY8Do0vDeB/sJMACP8hYuxs9/YAAAAABJRU5ErkJggg==')
      0 0 no-repeat;
  }
}

.PD-widget_config-hidden {
  display: none;
}

.PD-widget:hover .PD-widget_config {
  opacity: 1;
}

.PD-widget_zindex {
  background-color: #5d5d5d;
  border-radius: 9px;
  bottom: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  height: 18px;
  left: calc(50% - 9px);
  line-height: 16px;
  padding: 2px 4px;
  position: absolute;
  text-align: center;
  min-width: 18px;

  opacity: 0;
  transition: opacity 0.4s;
}

.PD-widget:hover .PD-widget_zindex {
  opacity: 1;
}

.PD-widget_zindex-plus {
  color: #cccccc;
  cursor: pointer;
  font-size: 32px;
  position: absolute;
  right: -23px;
  top: 2px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */

  opacity: 0;
  transition: opacity 0.4s;

  &:hover {
    color: #000000;
  }
}

.PD-widget_zindex:hover .PD-widget_zindex-plus {
  opacity: 1;
}

.PD-widget_zindex-minus {
  color: #cccccc;
  cursor: pointer;
  font-size: 32px;
  left: -23px;
  position: absolute;
  top: 2px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */

  opacity: 0;
  transition: opacity 0.4s;

  &:hover {
    color: #000000;
  }
}

.PD-widget_zindex:hover .PD-widget_zindex-minus {
  opacity: 1;
}

.PD-widget_zindex-hidden {
  display: none;
}

.PD-widget_duplicate {
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 26px;
  height: 20px;
  position: absolute;
  right: 32px;
  top: -4px;
  width: 20px;

  opacity: 0;
  transition: opacity 0.4s;

  // &:hover {
  //   background-color: transparent;
  // }
}

.PD-widget:hover .PD-widget_duplicate {
  opacity: 1;
}

.PD-widget_duplicate-hidden {
  display: none;
}

.PD-widget_delete {
  background-color: #c00000;
  border-radius: 20px;
  color: #fefefe;
  cursor: pointer;
  font-size: 17px;
  height: 20px;
  line-height: 19px;
  padding-left: 1px;
  right: 56px;
  top: 0px;
  position: absolute;
  width: 20px;

  opacity: 0;
  transition: opacity 0.4s;

  // &:hover {
  //   background-color: transparent;
  // }
}

.PD-widget:hover .PD-widget_delete {
  opacity: 1;
}

.PD-widget_delete-hidden {
  display: none;
}

.PD-widget_content {
  background: transparent;
  height: 100%;

  p {
    margin-top: 0;
  }
}

.PD-widget_content_title {
  margin-bottom: 5px;
  margin-top: 0;
}

.PD-widget_content_icon {
  border: 1px solid #ccc;
  border-radius: 60px;
  float: left;
  font-size: 40px;
  height: 60px;
  line-height: 60px;
  margin: 10px;
  text-align: center;
  width: 60px;
}

.PD-widget_content_data {
  float: right;
  font-size: 40px;
  height: 60px;
  line-height: 60px;
  margin: 0 10px 10px 10px;
  text-align: center;
  width: 50%;
}

.PD-content-modal {
  background: rgba(255, 255, 255, 0.9);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;
}

.PD-content-modal_window {
  background: rgba(200, 255, 255, 0.9);
  border: 2px dotted #ccc;
  left: 50%;
  margin-left: -210px;
  margin-top: -100px;
  padding: 20px;
  position: absolute;
  top: 20%;
  width: 400px;
  z-index: 100000;

  h2 {
    margin-top: 0;
  }
}

.PD-content-modal_window-close {
  border: 1px solid #000;
  border-radius: 100px;
  cursor: pointer;
  font-size: 14px;
  line-height: 10px;
  padding: 3px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: 5px;
  width: 10px;
}

.PD-content-modal_window-text {
  border: 1px solid #000;
  color: #000;
  margin-bottom: 5px;
  padding: 5px;
  width: 97%;
}

.PD-content-modal_window-select {
  border: 1px solid #000;
  color: #000;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
}

.PD-content-modal_window-buttons {
  margin-bottom: 0;
  text-align: right;
}

.PD-widget_content-image-title {
  left: 0;
  margin-bottom: 5px;
  margin-top: 0;
  position: absolute;
  top: 0;
}

.PD-widget_content-image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.PD-error {
  color: #f00;
}

.dashboards {
  flex-grow: 1;
}

.dashboards-toolbar_title {
  flex-grow: 1;

  span {
    font-size: 24px;
  }
}

.dashboards header {
  height: 50px;
  min-height: 50px;
}

.dashboards-toolbar {
  @media screen and (min-width: 600px) {
    min-height: 52px !important;
  }
}

.dashboards-toolbar .dashboards-toolbar-back {
  text-transform: none;
  min-height: 38px;
  min-width: 38px;
  height: 38px;
  width: 38px;
}

.dashboards .add-dashboard-button {
  margin-right: 35px;
}

.dashboards .menu-toogle-button {
  color: #ffffff;
  position: absolute;
  right: 15px;
  top: 51px;
  z-index: 10000;
}
